import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

import { RiDiscountPercentFill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
// import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
const StartCheckListModal = ({ shop, id }) => {
  function isNumber(n) {
    return typeof n === "number" && !isNaN(n) && isFinite(n);
  }
  console.log('StartCheckListModal', shop, id)
  const { startCheckListModal, startCheckLists, setStartCheckLists, setShopKassas, shopKassas, setStartCheckListModal, checkAuth, setDashboarPage, saleReports, setSaleReports, Notify, coffeeMashins, saleReportForms, setCoffeeMashins, setSaleReportSteps, scladShops, reciepts, setScladShops, setEditShopModal, admins, smenas, setSmenas, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [startCheckList, setStartCheckList] = useState(startCheckLists.find(pr => pr.id == id) ? startCheckLists.find(pr => pr.id == id) : null)
  console.log('startCheckList', startCheckList)

  const cm = coffeeMashins.find(pr => pr.shop_id == String(shop.id))
  console.log(cm)
  const cmReciepts = cm.reportsCounters.map(pr => typeof pr == 'string' ? JSON.parse(pr) : pr)
  const ref = useRef(null);
  const srform = saleReportForms.find(forms => forms.id == shop.slform_id)
  console.log('cmReciepts', cmReciepts)

  const filtered = srform.reciepts.filter(forms => {
    const parsedForm = typeof forms === 'string' ? JSON.parse(forms) : forms;
    // Отбираем только те элементы, для которых есть нужное значение
    return cmReciepts.find(pr => (pr.id == parsedForm.id) && parsedForm.kategory === 'Кофемашина');
  });
  const kassa = shopKassas.find(pr => pr.shop_id == shop.id)
  const couners = filtered.map(forms => {
    const parsedForm = typeof forms === 'string' ? JSON.parse(forms) : forms;
    const cmCounters = cmReciepts.find(pr => (pr.id == parsedForm.id) && parsedForm.kategory === 'Кофемашина');
    return {
      ...parsedForm,
      oldqty: cmCounters.counter,
      newqty: cmCounters.counter,
      comfirm: false
    }
  });
  const [counters, setCounters] = useState(couners)
  const [errorsCMs, setErrorssCMs] = useState(couners)
  const [errorsKassa, setErrorssKassa] = useState(couners)
  const [changeKassa, setChangeKassa] = useState(false)

  const nosorted = srform.sclad.map(pr => {
    const item = JSON.parse(pr)
    const itemShop = scladShops.find(pr => pr.prod_id == item.id && pr.shop_id == shop.id)
    return itemShop
  })

  const [newSR, setNewSR] = useState(nosorted.map(pr => { return { ...pr, newscldqty: pr.scldqty, why: '' } }))
  const [errorsnewSR, setErrorssnewSR] = useState(newSR)

  const [page, setPage] = useState('REPORT')


  function customSort2(a, b) {
    const categoriesOrder = {
      'эспрессо': 1,
      'американо': 2,
      'капучино': 3,
      'латте': 4,
      'мокаччино': 5,
      'раф-кофе': 6,
      'флэт-уайт': 7,
      'вишнессо': 8,
      'какао': 9,
      'какао': 10,
      'айс латте': 11,

      'чай': 12,
      'сливки': 13,
      'сироп': 14,
      'мёд': 15,
      'маршмеллоу': 16,
      'лимон': 17,
      'растительное молоко': 18,
      'молоко': 19,

      'пунш': 19,
      'глинтвейн': 20,
      'вода': 21,
      'мини-сок': 22,
      'мохито': 23,
      'лимонад': 24,
      'сангрия': 25,
      'холодный мятный чай': 26,
      'choco pie': 27,
      'сладкая вата': 28,
      'milky way': 29,
      'резинка': 30,
      'панини': 31,
      'кекс': 32,
      'круассан': 33,
      'Прочее': 34,
    };

    const getCategory = (name) => {
      for (const category in categoriesOrder) {
        if ((name.toLowerCase()).includes(category)) {
          return category;
        }
      }
      return 'Прочее';
    };

    const categoryA = getCategory(a.name);
    const categoryB = getCategory(b.name);

    if (categoryA !== categoryB) {
      return categoriesOrder[categoryA] - categoriesOrder[categoryB];
    } else {
      return a.name.localeCompare(b.name);
    }
  }

  const finish = async () => {
    seloading(true)
    try {


      let errorsCM = counters.map(pr => { return { ...pr, whyError: false, comfirmError: false } })
      let ErrorssnewSR = newSR.map(pr => { return { ...pr, whyError: false, comfirmError: false } })
      let kaassa = { newmoneyError: false, comfirmError: false, whyError: false }
      // console.log(errorsCM, ErrorssnewSR)
      for (let i = 0; i < ErrorssnewSR.length; i++) {
        if (!ErrorssnewSR[i].comfirm) {
          ErrorssnewSR = ErrorssnewSR.map(pr => {
            if (pr.id == ErrorssnewSR[i].id) {
              return { ...pr, comfirmError: true }
            } else { return { ...pr } }
          })

        }
        if (ErrorssnewSR[i].scldqty !== ErrorssnewSR[i].newscldqty && !ErrorssnewSR[i].why) {
          ErrorssnewSR = ErrorssnewSR.map(pr => {
            if (pr.id == ErrorssnewSR[i].id) {
              return { ...pr, whyError: true }
            } else { return { ...pr } }
          })

        }
      }
      for (let i = 0; i < errorsCM.length; i++) {
        if (!errorsCM[i].comfirm) {
          errorsCM = errorsCM.map(pr => {
            if (pr.id == errorsCM[i].id) {
              return { ...pr, comfirmError: true }
            } else { return { ...pr } }
          })

        }
        if (errorsCM[i].changeQty && !errorsCM[i].why) {
          errorsCM = errorsCM.map(pr => {
            if (pr.id == errorsCM[i].id) {
              return { ...pr, whyError: true }
            } else { return { ...pr } }
          })

        }
        if (changeKassa) {
          if (!isNumber(kassa.newmoney) || kassa.money == kassa.newmoney) {
            kaassa.newmoneyError = true
          } else {
            kaassa.newmoneyError = false

          }
          if (!kassa.why) {
            kaassa.whyError = true
          }
        }
        if (!kassa.comfirm) {
          kaassa.comfirmError = true

        }

      }
      setErrorssnewSR(ErrorssnewSR)
      setErrorssCMs(errorsCM)
      setErrorssKassa(kaassa)

      const hasError = errorsCM.some(pr => pr.whyError || pr.comfirmError) ||
        ErrorssnewSR.some(pr => pr.whyError || pr.comfirmError) || (kaassa.comfirmError || kaassa.newmoneyError || kaassa.whyError)


      // console.log(hasError);
      if (hasError) {
        seloading(false)
        Notify.addNotification({
          title: "Не весь чеклист заполнен!",
          message: "Заполните все поля выделенные красным! Необходимо подтвердить все данные, а если что-то изменилось, написать почему.",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      } else {

        console.log(errorsCM, ErrorssnewSR, kaassa)
        seloading(true)

        const data = await $api.post(`${BACKURL}/api/checklist/startchecklist/finish/${id}`, {
          counters: errorsCM, sclad: ErrorssnewSR, kassa: { ...kassa, ...kaassa, changeKassa: changeKassa }, admin_id: admin.id, shop_id: shop.id
        })
        console.log(data.data)

        setCoffeeMashins([data.data.cm])


        setShopKassas([data.data.shopKassa])
        console.log(shopKassas.map(pr => {
          if (pr.id == data.data.shopKassa.id) {
            return data.data.shopKassa.id
          } else return pr
        }))
        // startCheckLists,setStartCheckList
        // 
        // shopKassas, setShopKassass
        setStartCheckLists(startCheckLists.map(pr => {
          if (pr.id == data.data.StartCheckLists.id) {
            return data.data.StartCheckLists.id
          } else return pr
        }))
        setScladShops(data.data.scladShop)
        // let t =scladShop
        // for (let i = 0; i < data.data.scladShop.length; i++) {

        // }
        setSmenas([data.data.smena])
        setStartCheckListModal(false)

        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Чеклист отправлен. Смена открыта исходя из данных чеклиста!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      }
    } catch (e) {
      console.log(e)
      seloading(false)
    }
  }


  const submit = async () => {
    confirmAlert({
      title: 'Отправить отчет?',
      message: 'Вы уверены, что хотите отправить отчет? Изменить его уже будет нельзя! Вы внимательно проверили данные?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await finish()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "startCheckListModald" && setStartCheckListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  // console.log(totlaMoney, totalsale, totallost, totalsalewithoutcup, totallostwithoutcup)
  return (
    <>
      {/* <ReactNotifications /> */}
      {startCheckListModal && (
        <div
          onClick={closeModal}
          id="startCheckListModald" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
          <div className={style.borders2} style={{ height: 'inherit' }}>
            <h2 id={'select'} ref={ref} style={{ color: "white", textAlign: 'center' }}> {shop.name}</h2>
            <h3 style={{ color: "white", textAlign: 'center' }}>Чек-лист открытия смены</h3>
            {/* <h4 style={{ color: "white", textAlign: 'center' }}>{ startCheckList?new Date(startCheckList.createdAt):'?'}</h4> */}


            <div className={style.nextitem}>
              {/* <div className={style.uuu} style={{ flexDirection: 'row' }}>
                <BiSolidSave style={{ color: "white" }} onClick={() => savesr()}></BiSolidSave>

              </div>
              {page != 'ACTION' && ( */}
              < Button
                text="Выход"
                background="var(--primary-color)"
                color="white"
                width="100px"
                onClick={() => {
                  setStartCheckListModal(false)
                  setDashboarPage('Shops')
                  //+удалить смену
                }}
              />
              {/* )} */}

              < Button
                text="открыть смену"
                background="darkslateblue"
                color="white"
                width="100px"
                onClick={() => submit()}
              />

            </div>
            {page == 'REPORT' && (
              <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
                <Tabs >
                  <TabList style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                    <Tab style={{ background: 'white', margin: 0, borderRadius: '15px', padding: '5px', border: 'none', color: (errorsKassa.comfirmError || errorsKassa.newmoneyError || errorsKassa.whyError) ? 'red' : "black" }} key={"Касса"}><h5 style={{ margin: 0 }}>Касса</h5></Tab>

                    <Tab style={{ background: 'white', margin: 0, borderRadius: '15px', padding: '5px', border: 'none', color: errorsCMs.some(pr => pr.whyError || pr.comfirmError) ? 'red' : "black" }} key={'Счетчики'}><h5 style={{ margin: 0 }}>Счетчики</h5></Tab>
                    <Tab style={{ background: 'white', margin: 0, borderRadius: '15px', padding: '5px', border: 'none', color: errorsnewSR.some(pr => pr.whyError || pr.comfirmError) ? 'red' : "black" }} key={'Склад'}><h5 style={{ margin: 0 }}>Склад</h5></Tab>
                  </TabList>
                  {/* counters */}
                  <TabPanel>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', background: 'white', borderRadius: '15px', padding: '15px' }}>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <h2 style={{
                          color: 'black'
                        }}>
                          {kassa.money}руб
                        </h2>
                        {!changeKassa && (
                          < Button
                            text="Изменить"
                            background="var(--primary-color)"
                            color="white"
                            width="100px"
                            onClick={() => {
                              setChangeKassa(true)
                            }}
                          />
                        )}
                        {changeKassa && (
                          < Button
                            text="Отмена"
                            background="var(--primary-color)"
                            color="white"
                            width="100px"
                            onClick={() => {
                              setChangeKassa(false)
                            }}
                          />
                        )}
                        {!changeKassa && (
                          <div style={{ width: "15%" }}
                            onClick={(e) => {
                              // console.log(cof)
                              setShopKassas(shopKassas.map(pr => {
                                if (pr.id == kassa.id) {
                                  return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true }
                                } else {
                                  return pr
                                }
                              }))
                            }}
                            value={kassa.comfirm}
                          >{kassa.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsKassa ? errorsKassa.comfirmError ? 'red' : null : null }} />}
                          </div>
                        )}
                        {changeKassa && (
                          <div style={{ width: "40%", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <input
                              style={{
                                width: "100%", textAlign: "center", border: '1px solid black', borderRadius: '15px',
                                background: errorsKassa ? errorsKassa.newmoneyError ? 'red' : 'white' : 'white'

                              }}
                              type="number"
                              step={0.01}

                              onChange={(e) => {
                                // console.log(cof)
                                setShopKassas(shopKassas.map(pr => {
                                  if (pr.id == kassa.id) {
                                    return { ...pr, newmoney: Number(e.target.value) }
                                  } else {
                                    return pr
                                  }
                                }))
                              }}
                              value={kassa.newmoney} placeholder="Введите новый остаток наличных">

                            </input>


                          </div>
                        )}


                      </div>
                      {changeKassa && (
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <textarea
                            style={{
                              width: "75%", textAlign: "center", color: 'black',
                              background: errorsKassa ? errorsKassa.whyError ? 'red' : 'white' : 'white'


                            }}
                            onChange={(e) => {
                              // console.log(e)counters, setCounters
                              setShopKassas(shopKassas.map(pr => {
                                if (pr.id == kassa.id) {
                                  return { ...pr, why: e.target.value }
                                } else {
                                  return pr
                                }
                              }))

                            }}
                            value={kassa.why ? kassa.why : null} placeholder={`Напишите почему остаток наличных в кассе не совпадает со вчерашним значением?`}
                          />

                          <div style={{ width: "15%" }}
                            onClick={(e) => {
                              // console.log(cof)
                              setShopKassas(shopKassas.map(pr => {
                                if (pr.id == kassa.id) {
                                  return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true }
                                } else {
                                  return pr
                                }
                              }))
                            }}
                            value={kassa.comfirm}
                          >{kassa.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsKassa ? errorsKassa.comfirmError ? 'red' : null : null }} />}
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {counters.map((cof, index) => <>


                      <div className={style.itemdd} style={{ justifyContent: 'space-evenly' }}>
                        {/* <div style={{width:"100%"}}> */}
                        <div style={{ width: "40%" }}>{cof.name}</div>
                        {/* <div  style={{width:"30%"}}> */}
                        {/* <div style={{ width: "20%", opacity: "0.65" }}>{cof.oldqty}</div> */}
                        <div style={{ width: "30%", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                          <input
                            style={{
                              width: "70%", textAlign: "center", border: '1px solid black', borderRadius: '15px'

                            }}
                            type="number"
                            step={1}

                            onChange={(e) => {
                              // console.log(e)counters, setCounters
                              setCounters(counters.map(pr => {
                                if (pr.id == cof.id) {
                                  return { ...pr, newqty: Number(e.target.value), comfirm: false, changeQty: pr.oldqty == Number(e.target.value) ? false : true }
                                } else {
                                  return pr
                                }
                              }))
                            }}
                            value={cof.newqty} placeholder="На конец дня">

                          </input>
                          {/* <div> */}
                          {cof.oldqty == cof.newqty && (<>
                            <div style={{ width: "15%" }}
                              onClick={(e) => {
                                // console.log(cof)
                                setCounters(counters.map(pr => {
                                  if (pr.id == cof.id) {
                                    return { ...pr, comfirm: pr.comfirm ? false : true, changeQty: pr.oldqty == Number(pr.newqty) ? false : true }
                                  } else {
                                    return pr
                                  }
                                }))
                              }}
                              value={cof.comfirm}
                            >{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsCMs ? errorsCMs.find(pr => pr.id == cof.id).comfirmError ? 'red' : null : null }} />}</div>
                          </>)}

                          {/* </div> */}

                        </div>

                        {/* </div> */}

                      </div>
                      {cof.oldqty != cof.newqty && (<>
                        <div className={style.itemdd} style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                          <div style={{ color: 'black', textAlign: 'center', justifyContent: 'space-evenly' }}> Счетчик <b>{cof.name}</b> не совпадает со вчерашним вечерним значением</div>
                          <div style={{ width: "15%" }}
                            onClick={(e) => {
                              // console.log(cof)
                              setCounters(counters.map(pr => {
                                if (pr.id == cof.id) {
                                  return { ...pr, comfirm: pr.comfirm ? false : true, changeQty: pr.oldqty == Number(pr.newqty) ? false : true }
                                } else {
                                  return pr
                                }
                              }))
                            }}
                            value={cof.comfirm}
                          >{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsCMs ? errorsCMs.find(pr => pr.id == cof.id).comfirmError ? 'red' : null : null }} />}</div>
                        </div>
                        <textarea
                          style={{
                            width: "100%", textAlign: "center",
                            // background: errorsCMs ? errorsCMs.find(pr => pr.id == cof.id).whyError ? 'red' : 'white' : 'white',
                            background: errorsCMs?.find(pr => pr.id == cof.id)?.whyError ? "red" : "white", // Цвет введенного текста


                          }}
                          onChange={(e) => {
                            // console.log(e)counters, setCounters
                            setCounters(counters.map(pr => {
                              if (pr.id == cof.id) {
                                return { ...pr, why: e.target.value }
                              } else {
                                return pr
                              }
                            }))
                          }}
                          value={cof.why ? cof.why : null} placeholder={`Напишите почему счетчик ${cof.name} не совпадает со вчерашним значением?`}
                        />

                      </>

                      )}
                    </>

                    )}
                  </TabPanel>

                  <TabPanel>
                    <div style={{ display: "flex", flexDirection: "row", textAlign: 'center', justifyContent: 'space-between' }}>
                      <div style={{ width: "35%", color: "white" }}><h6>Наименование</h6></div>
                      {/* <div  style={{width:"30%"}}> */}
                      {/* <div style={{ width: "10%", color: "white" }}><h6>+</h6></div>
                      <div style={{ width: "10%", color: "white" }}><h6>-</h6></div> */}
                      <div style={{ width: "15%", color: "white" }}><h6>Вчера вечер</h6></div>
                      <div style={{ width: "15%", color: "white" }}><h6>Сегодня утро</h6></div>
                      <div style={{ width: "15%", color: "white" }}><h6><FaCheck /></h6></div>
                    </div>
                    <div>
                      {newSR.map((cof, index) => <><div className={style.itemdd} >


                        {/* </div> */}
                        <div style={{ width: "35%" }}>{cof.name}</div>
                        {/* <div  style={{width:"30%"}}> */}
                        {/* <div style={{ width: "10%" }}>{cof.prihod}</div>
                        <div style={{ width: "10%" }}>{cof.rashod}</div> */}
                        <div style={{ width: "15%" }}>{cof.scldqty}</div>
                        <div style={{ width: "15%" }}>
                          <input
                            type="number"
                            step={0.01}
                            style={{ width: "100%", textAlign: "center", border: '1px solid black', borderRadius: '15px' }}
                            onChange={(e) => {
                              // console.log(e)
                              setNewSR(newSR.map(pr => {
                                if (pr.prod_id == cof.prod_id && pr.name == cof.name) {
                                  return { ...pr, comfirm: false, newscldqty: Number(e.target.value), changeQty: Number(pr.scladqty) == Number(e.target.value) ? false : true }
                                } else {
                                  return pr
                                }
                              }))

                            }}
                            value={cof.newscldqty} placeholder="На конец дня">

                          </input>
                        </div>
                        {/* {cof.scldqty == cof.newscldqty && ( */}
                        <div style={{ width: "15%" }}
                          onClick={(e) => {
                            // console.log(e)
                            setNewSR(newSR.map(pr => {
                              if (pr.prod_id == cof.prod_id && pr.name == cof.name) {
                                return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true, changeQty: Number(pr.scldqty) == Number(pr.newscldqty) ? false : true }
                              } else {
                                return pr
                              }
                            }))
                          }}
                          value={cof.comfirm}
                        >

                          {(cof.scldqty == cof.newscldqty) && (
                            <>{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsnewSR ? errorsnewSR.find(pr => pr.id == cof.id).comfirmError ? 'red' : null : null }} />}</>
                          )}

                        </div>
                        {/* )} */}
                      </div>
                        {(cof.scldqty !== cof.newscldqty) && (<>
                          <div className={style.itemdd} >
                            <div style={{ color: 'black', textAlign: 'center', justifyContent: 'space-evenly' }}> Остаток <b>{cof.name}</b> не совпадает со вчерашним вечерним значением</div>
                            <div style={{ width: "15%" }}
                              onClick={(e) => {
                                // console.log(cof)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id) {
                                    return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true, changeQty: Number(pr.scldqty) == Number(pr.newscldqty) ? false : true }
                                  } else {
                                    return pr
                                  }
                                }))
                              }}
                              value={cof.comfirm}
                            >{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked style={{ color: errorsnewSR ? errorsnewSR.find(pr => pr.id == cof.id).comfirmError ? 'red' : null : null }} />}</div>
                          </div>
                          <textarea
                            style={{
                              width: "100%", textAlign: "center", color: 'black',
                              background: errorsnewSR?.find(pr => pr.id == cof.id)?.whyError ? "red" : "white", // Цвет введенного текста

                            }}
                            onChange={(e) => {
                              // console.log(e)counters, setCounters
                              setNewSR(newSR.map(pr => {
                                if (pr.id == cof.id) {
                                  return { ...pr, why: e.target.value }
                                } else {
                                  return pr
                                }
                              }))
                            }}
                            value={cof.why ? cof.why : null} placeholder={`Напишите почему остаток ${cof.name} не совпадает со вчерашним значением?`}
                          />

                        </>

                        )}  </>
                      )}
                    </div>

                  </TabPanel>




                </Tabs >


              </div>
            )}

          </div>

        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }

    </>
  )
}
export default StartCheckListModal;