import { createContext, useEffect, useState, useCallback } from "react";
import axios from 'axios'
import $api from "../http/index.ts";
import { BACKURL } from "../conf.js"
import Loading from 'react-fullscreen-loading';
import { Store } from 'react-notifications-component';

export const QtdContext = createContext();
// import ProgressBar from "@ramonak/react-progress-bar";
const QtdProvider = (props) => {

  const [admin, setAdmin] = useState({ auth: false, user: '' });
  const [admins, setAdmins] = useState([])
  const [sclad, setSclad] = useState([]);
  const [scladShops, setScladShops] = useState([]);
  const [scladHistory, setScladHistory] = useState([]);
  const [scladHistoryShops, setScladHistoryShops] = useState([]);
  const [scladOrders, setScladOrders] = useState([]);
  const [shops, setShops] = useState([]);
  const [reciepts, setReciepts] = useState([]);
  const [saleReports, setSaleReports] = useState([]);
  const [avansReports, setAvansReports] = useState([]);
  const [avansReportsForms, setAvansReportsForms] = useState([]);
  const [smenas, setSmenas] = useState([]);
  const [loading, setLoading] = useState(true)

  const [tgorders, setTGorders] = useState([])
  const [saleReportForms, setSaleReportForms] = useState([])
  const [coffeeMashins, setCoffeeMashins] = useState([])
  const [dashboarPage, setDashboarPage] = useState('Home')

  const [addProductModal, setAddProductModal] = useState(false);
  const [addShopModal, setAddShopModal] = useState(false);
  const [addEmployeesModal, setAddEmployeesModal] = useState(false);
  const [editEmployeesModal, setEditEmployeesModal] = useState(false);
  const [addCoffeeMashinssModal, setAddCoffeeMashinssModal] = useState(false);
  const [editCoffeeMashinssModal, setEditCoffeeMashinssModal] = useState(false);
  const [coffeeMashinsListModal, setCoffeeMashinListsModal] = useState(false);
  const [startCheckListModal, setStartCheckListModal] = useState(false);

  const [rashodModal, setRashodModal] = useState(false);
  const [prihodModal, setPrihodModal] = useState(false);
  const [rashodModalShop, setRashodModalShop] = useState(false);
  const [prihodModalShop, setPrihodModalShop] = useState(false);
  const [scladHistoryListModal, setScladHistoryListModal] = useState(false);
  const [makeOrderModal, setMakeOrderModal] = useState(false);
  const [scladOrderListModal, setScladOrderListModal] = useState(false);
  const [scladInventarisationModal, setScladInventarisationModal] = useState(false);
  const [scladInventarisationModalShop, setScladInventarisationModalShop] = useState(false);
  const [editScladOrderListItemModal, setEditScladOrderListItemModal] = useState(false);
  const [addScladOrderListItemModal, setAddScladOrderListItemModal] = useState(false);
  const [scladSelectedOrdersModal, setScladSelectedOrdersModal] = useState(false);
  const [editShopModal, setEditShopModal] = useState(false);
  const [addReciepModal, setAddRecieptModal] = useState(false);
  const [recieptListModal, setRecieptListModal] = useState(false);
  const [addRecieptOrderListModal, setAddRecieptOrderListModal] = useState(false);
  const [editRecieptListModal, setEditRecieptListModal] = useState(false);
  const [editRecieptModal, setEditRecieptModal] = useState(false);
  const [createSRModal, setCreateSRModal] = useState(false);
  const [linkProductModal, setLinkProductModal] = useState(false);
  const [saleReportSteps, setSaleReportSteps] = useState(false);
  const [avansReportModal, setAvansReportModal] = useState(false);
  const [createavansReportModal, setCreateAvansReportModal] = useState(false);
  const [saleReportShow, setSaleReportShow] = useState(false);
  const [saleReportShortShow, setSaleReportShortShow] = useState(false);
  const [createCoffeeMashins, setCreateCoffeeMashins] = useState(false);
  const [srListModal, setSRListModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [comfirmReportModal, setComfirmReportModal] = useState(false);
  const [editSaleReportForm, setEditSaleReportForm] = useState(false);
  const [replaseModalShop, setReplaseModalShop] = useState(false);
  const [addContrAgentsModal, setAddContrAgentsModal] = useState(false);
  const [contragents, setContrAgents] = useState([])
  const [contragentsPrihods, setContrAgentsPrihods] = useState([])
  const [addCARashod, setaddCARashod] = useState(false)
  const [editCARashod, seteditCARashod] = useState(false)
  const [shopKassas, setShopKassas] = useState(false)
  const [incosations, setIncosations] = useState([])
  const [avansReportsFormsModal, setAvansReportsFormsModal] = useState(false)
  const [editShopIncasationsModal, setEditShopIncasationsModal] = useState(false)
  const [editShopKassasModal, setEditShopKassasModal] = useState(false)
  const [startCheckLists, setStartCheckLists] = useState([])
  const checkAuth = async () => {
    try {
      // seloading(true)

      const response = await axios.get(`${BACKURL}/api/utbteam/refresh`,
        { withCredentials: true, secure: true }
      )
      // console.log('checkAuth()', 'response');
      // console.log('================', response)
      localStorage.setItem('token', response.data.accessToken);
      setAdmin({ auth: true, fullname: response.data.user.fullname, id: response.data.user.id, user: response.data.user.login, role: response.data.user.role });
      const page = localStorage.getItem('dashboardpage')
      if (page) {
        setDashboarPage(page)
      }
      setLoading(false)
      // setLoading(false)
      return true
    } catch (e) {
      // console.log(e.response?.data?.message);
      setAdmin({ auth: false, user: '' });
      setLoading(false)
      //  Store.addNotification({
      //     title: "Ошибка!",
      //     message: "Залогиньтесь в системе заново!",
      //     type: "danger",
      //     insert: "bottom",
      //     container: "bottom-center",
      //     animationIn: ["animate__animated", "animate__fadeIn"],
      //     animationOut: ["animate__animated", "animate__fadeOut"],
      //     dismiss: {
      //       duration: 5000,
      //       onScreen: true
      //     }
      //   })

    }
  }



  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (localStorage.getItem('token')) {
        // console.log('have access token!')
        await checkAuth()
      }
      setLoading(false)
      return 'ok'
    }

    fetchData()
    // console.log(2)

  }, []);
  return (
    <>
      {loading ? <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        :
        <QtdContext.Provider value={{
          startCheckListModal, setStartCheckListModal,
          editShopIncasationsModal, setEditShopIncasationsModal,
          avansReportsForms, setAvansReportsForms,
          avansReportsFormsModal, setAvansReportsFormsModal,
          shopKassas, setShopKassas,
          editShopKassasModal, setEditShopKassasModal,
          contragentsPrihods, setContrAgentsPrihods,
          editCARashod, seteditCARashod,
          contragents, setContrAgents,
          avansReports, setAvansReports,
          createavansReportModal, setCreateAvansReportModal,
          avansReportModal, setAvansReportModal,
          admin, setAdmin,
          admins, setAdmins,
          sclad, setSclad,
          setTGorders, tgorders,
          reciepts, setReciepts,
          addContrAgentsModal, setAddContrAgentsModal,
          saleReportShortShow, setSaleReportShortShow,
          addReciepModal, setAddRecieptModal,
          shops, setShops,
          addProductModal, setAddProductModal,
          addShopModal, setAddShopModal,
          addEmployeesModal, setAddEmployeesModal,
          dashboarPage, setDashboarPage,
          rashodModal, setRashodModal,
          prihodModal, setPrihodModal,
          scladHistory, setScladHistory,
          scladHistoryListModal, setScladHistoryListModal,
          makeOrderModal, setMakeOrderModal,
          scladOrders, setScladOrders,
          scladOrderListModal, setScladOrderListModal,
          scladInventarisationModal, setScladInventarisationModal,
          editScladOrderListItemModal, setEditScladOrderListItemModal,
          addScladOrderListItemModal, setAddScladOrderListItemModal,
          editEmployeesModal, setEditEmployeesModal,
          scladSelectedOrdersModal, setScladSelectedOrdersModal,
          editShopModal, setEditShopModal,
          recieptListModal, setRecieptListModal,
          addRecieptOrderListModal, setAddRecieptOrderListModal,
          editRecieptListModal, setEditRecieptListModal,
          scladShops, setScladShops,
          saleReports, setSaleReports,
          editRecieptModal, setEditRecieptModal,
          rashodModalShop, setRashodModalShop,
          prihodModalShop, setPrihodModalShop,
          scladHistoryShops, setScladHistoryShops,
          smenas, setSmenas,
          createSRModal, setCreateSRModal,
          linkProductModal, setLinkProductModal,
          saleReportForms, setSaleReportForms,
          saleReportSteps, setSaleReportSteps,
          coffeeMashins, setCoffeeMashins,
          createCoffeeMashins, setCreateCoffeeMashins,
          editCoffeeMashinssModal, setEditCoffeeMashinssModal,
          addCoffeeMashinssModal, setAddCoffeeMashinssModal,
          coffeeMashinsListModal, setCoffeeMashinListsModal,
          srListModal, setSRListModal,
          Notify: Store,
          scladInventarisationModalShop, setScladInventarisationModalShop,
          saleReportShow, setSaleReportShow,
          editProductModal, setEditProductModal,
          editSaleReportForm, setEditSaleReportForm,
          comfirmReportModal, setComfirmReportModal,
          replaseModalShop, setReplaseModalShop,
          checkAuth,
          addCARashod, setaddCARashod,
          incosations, setIncosations,
          startCheckLists, setStartCheckLists
        }}>
          {props.children}
        </QtdContext.Provider>
      }

    </>
  );
};

export default QtdProvider;
