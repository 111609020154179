import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FcCancel, FcServices } from "react-icons/fc";

const PrihodModalShop = ({ shop_id }) => {

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { setSmenas, sclad, smenas, Notify, prihodModalShop, setPrihodModalShop, setScladShops, scladShops, setScladHistoryShops, setSclad, admin, scladHistory, setScladHistory } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({
    shop_id: shop_id,
    type: 'Приход',
    kategory: '',
    prod_id: '',
    qty: '',

  })
  const shopssclad = scladShops.filter(pr => pr.shop_id === Number(shop_id))

  const [prodArr, setProdArr] = useState([])

  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "c22ontainer12464" && setPrihodModalShop(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const dlt = (prod_id) => {
    setProdArr(prodArr.filter(pr => pr.prod_id != prod_id))
  }

  const [err, setErr] = useState({
    prod_id: false,
    kategory: false,
    qty: false,
    type: false,
  })
  const addProd = async (e) => {
    e.preventDefault()
    let t = {
      prod_id: false,
      kategory: false,
      qty: false,
      type: false,
    }
    if (!newValue.prod_id) {
      t.prod_id = `Выберите товар!`

    }
    if (!newValue.qty || Number(newValue.qty) < 0) {
      t.qty = `Введите положительное число!`

    }

    // }
    setErr(t)
    if (
      !t.prod_id &&
      !t.qty &&
      newValue.prod_id,
      newValue.qty

    ) {
      const product = sclad.find(pr => pr.id == Number(newValue.prod_id))

      setProdArr([...prodArr, {
        prod_id: newValue.prod_id,
        qty: newValue.qty,
        name: product.name,
        kategory: product.kategory,
        mnt: product.mnt,
      }])

      setNeValue({
        type: 'Приход',
        shop_id: shop_id,
        kategory: newValue.kategory,
        prod_id: '',
        qty: '',
      })
    }
  }
  const editPrice = async (e) => {
    try {
      e.preventDefault()

      let t = {
        prod_id: false,
        kategory: false,
        qty: false,
        err: false,
      }


      if (!newValue.kategory) {
        t.kategory = `Выберите причину!`

      }

      if (!newValue.type) {
        t.type = `Не указан тип!`

      }
      if (!prodArr.length > 0) {
        t.err = `Выберите товары!`

      }

      setErr(t)
      if (!t.type &&
        !t.kategory &&
        !t.err &&
        newValue.type,
        newValue.kategory) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/scladhistoryshop/create`, { prodArr: prodArr, newValue: newValue, admin: admin.id, shop_id })
        setScladHistoryShops(newProd.data.scladhistory);
        setSmenas(smenas.map(pr => { if (pr.id == newProd.data.smenas.id) { return newProd.data.smenas } else { return pr } }));
        setScladShops(newProd.data.sclad);
        seloading(false);
        setPrihodModalShop(false);
        Notify.addNotification({
          title: "Готово!",
          message: "Товары успешно оприходованны у вас на складе!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }

  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));
  // console.log(uniqueSerials, sclad)
  const nosirops = sclad.filter(pr => pr.kategory != 'Сироп')
  const sorted = nosirops.sort((a, b) => a.name > b.name ? 1 : -1);


  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>

      {prihodModalShop && (
        <div onClick={closeModal} id="c22ontainer12464" className={style.modal_container}>
          {/*  */}
          {/* <div className={style.modal}> */}

          {/* <form className={style.form}> */}

          <div className={style.borders}>
            {prodArr.length > 0 && (
              <div className={style.inputtest}>
                <ol>
                  {prodArr.map(pr => (
                    <li>
                      <div className={style.list}>
                        <div className={style.itemlist}>
                          {pr.name}  x  {pr.qty}{pr.mnt}
                        </div>
                        <div className={style.actionlist}>
                          <FcCancel onClick={() => dlt(pr.prod_id)} />

                        </div>
                      </div>
                    </li>
                  ))}
                </ol>

              </div>
            )}

            <div className={style.inputtest}>

              <label >Выберите товар:</label>

              <select name="pets" id="prod"
                value={newValue.prod_id}
                onChange={(event) => {
                  setNeValue({ ...newValue, prod_id: event.target.value });
                }}

              >
                <option value="">--Выберите товар--</option>

                {uniqueSerials.map(serials => {

                  const table = sorted.filter(prod => prod.kategory === serials)

                  return (<>
                    <optgroup label={`${serials}`}>
                      {table.map(prod => (
                        <option value={`${prod.id}`}>{prod.name}</option>

                      ))}
                    </optgroup></>
                  )
                }
                )}


              </select>
              {err.prod_id && (
                <p style={{ color: "red" }}>{err.prod_id}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Введите количество:</label>

              <input value={newValue.qty} type="number" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, qty: event.target.value });
              }} />
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>
            <Button
              text="Добавить товар"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={addProd}
            />
            {err.err && (
              <p style={{ color: "red" }}>{err.err}</p>
            )}


            <div className={style.inputtest}>
              <label >Выберите причину прихода:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, kategory: event.target.value });
                }}
              >
                <option value="">--Выберите причину--</option>
                <option value="Поставка">Поставка</option>
                <option value="Прочее">Прочее</option>
              </select>
              {err.kategory && (
                <p style={{ color: "red" }}>{err.kategory}</p>
              )}
            </div>

            <div className={style.inputtest}>

              <label >Введите примечание/поставщик/номер накладной:</label>

              <input type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, comment: event.target.value });
              }} />
              {err.comment && (
                <p style={{ color: "red" }}>{err.comment}</p>
              )}
            </div>

            {prodArr.length > 0 && (
              <Button
                text="Оприходывать"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={editPrice}
              />
            )}
          </div>
          {/* </form> */}

          {/* </div> */}

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default PrihodModalShop;
